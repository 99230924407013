import jwt_decode from "jwt-decode";

export const getToken = () =>{
    let token = localStorage.getItem('token');
    return token? token : '';
}

export const getTokenInfo = ()=> {
 let token = getToken();
  if(token){
      return jwt_decode(token);
  }
  return null
}

