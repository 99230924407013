import * as api from "../config/apiConfig";
import http from "./httpService";

const subBaseUrl = "/api/Analytics";
const serviceBaseUrl = `${api.BASE_ORDER_URL}${subBaseUrl}`;
export function getOrderSnapshotService({ vendorId }) {
  //vendorId=70CB524D-E0D3-4A56-1E18-08D919271C72
  return http.get(
    `${serviceBaseUrl}/OrderContractsDelivery?vendorId=${vendorId}`
  );
}

export function RevenueSnapShotService({
  vendorId,
  period,
  description = "",
  from = "",
  to = "",
}) {
  return http.get(
    `${serviceBaseUrl}/ProductsRevenue?vendorId=${vendorId}&period=${period}&description=${description}&from=${"2022-04-10"}&to=${"2022-12-22"}`
  );
}

export function AssetUtilizationService({ vendorId }) {
  return http.get(`${serviceBaseUrl}/AssetUtilization?vendorId=${vendorId}`);
}

export function OrderValueService({ vendorId, period }) {
  return http.get(
    `${serviceBaseUrl}/OrderValue?vendorId=${vendorId}&period=${period}`
  );
}

export function TopCustomerAndRecentOrderService({
  vendorId,
  period,
  description = "",
  from = "",
  to = "",
}) {
  return http.get(`${serviceBaseUrl}/TopCustomersRecentOrders?vendorId=${vendorId}&period=${period}
  &description=${description}&from=${from}&to=${to}`);
}
export default {
  getOrderSnapshotService,
  OrderValueService,
  GetAssetUtilizationService: AssetUtilizationService,
  TopCustomerAndRecentOrderService,
  RevenueSnapShotService,
};
