export const APPROVE_ORDER = "APPROVE_ORDER";
export const APPROVE_ORDER_START = "APPROVE_ORDE_START";
export const APPROVE_ORDER_SUCCESS = "APPROVE_ORDE_SUCCESS";
export const APPROVE_ORDER_FAIL = "APPROVE_ORDER_FAIL";

export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_START = "FETCH_ORDERS_START";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";

export const AUTH_CHECK_STATE = "AUTH_CHECK_STATE";
export const AUTH_CHANGE_PASSWORD = "AUTH_CHANGE_PASSWORD";
export const FETCH_VENDORUSER_PROFILE = "FETCH_VENDORUSER_PROFILE";
export const FETCH_VENDORUSER_PROFILE_SUCCESS =
  "FETCH_VENDORUSER_PROFILE_SUCCESS";
export const AUTH_USER = "AUTH_USER";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_TIMEOUT";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SET_CURRENTUSER = "AUTH_SET_CURRENTUSER";
export const AUTH_UPDATE_USERPROFILE = "AUTH_UPDATE_USERPROFILE";
export const AUTH_UPDATE_USERPROFILE_SUCCESS =
  "AUTH_UPDATE_USERPROFILE_SUCCESS";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

//SIDEBAR
export const SIDEBAR_START_LOADING = "SIDEBAR_START_LOADING";
export const SIDEBAR_STOP_LOADING = "SIDEBAR_STOP_LOADING";
export const PAGE_START_LOADING = "PAGE_START_LOADING";
export const PAGE_STOP_LOADING = "PAGE_STOP_LOADING";
export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const OPEN_PROFILE_SIDEBAR = "OPEN_PROFILE_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const OPEN_EDIT_VENDOR_SIDEBAR = "OPEN_EDIT_VENDOR_SIDEBAR";
export const CLOSE_EDIT_VENDOR_SIDEBAR = "CLOSE_EDIT_VENDOR_SIDEBAR";

//PRODUCTS
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";

export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CONTRACT_STATUS_SUCCESS = "UPDATE_CONTRACT_STATUS_SUCCESS";
export const UPDATE_CONTRACT_STATUS = "UPDATE_CONTRACT_STATUS";
export const FETCH_CONTRACT_DETAILS = "FETCH_CONTRACT_DETAILS";
export const FETCH_CONTRACT_DETAILS_SUCCESS = "FETCH_CONTRACT_DETAILS_SUCCESS";
export const UPDATE_CONTRACT_DETAILS = "UPDATE_CONTRACT_DETAILS";

//ORDER
export const FETCH_ORDER_ITEMS = "FETCH_ORDER_ITEMS";
export const UPDATE_ORDER_ITEMS = "UPDATE_ORDER_ITEM";
export const SELECT_ORDER_ITEMS = "SELECT_ORDER_ITEMS";
export const SCHEDULE_ORDER_ITEMS = "SELECT_ORDER_ITEMS";
export const UPDATE_ORDER_ITEMS_SUCCESS = "UPDATE_ORDER_ITEMS_SUCCESS";
export const FETCH_DELIVERY_ORDERS = "FETCH_DELIVERY_ORDERS";
export const FETCH_DELIVERY_ORDERS_SUCCESS = "FETCH_DELIVERY_ORDERS_SUCCESS";

//DASHBOARD
export const FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA";
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";

//ACCOUNT
export const ACCOUNT_CREATE_BULK_USER = "ACCOUNT_CREATE_BULK_USER";
export const ACCOUNT_CREATE_BULK_USER_SUCCESS =
  "ACCOUNT_CREATE_BULK_USER_SUCCESS";
export const ACCOUNT_BULK_USER_RESET = "ACCOUNT_BULK_USER_RESET";
// export const FETCH_VENDOR_DASHBOARD_DATA = "FETCH_VENDOR_DASHBOARD_DATA";
// export const FETCH_PROC_DASHBOARD_DATA = "FETCH_PROC_DASHBOARD_DATA";

// Reports
export const GET_REPORT_LOGS_FAILURE = "GET_REPORT_LOGS_FAILURE";
export const GET_REPORT_LOGS_SUCCESS = "GET_REPORT_LOGS_SUCCESS";
export const GET_REPORT_LOGS = "GET_REPORT_LOGS";
export const GET_REPORT_LOGS_PDF_DOWNLOAD = "GET_REPORT_LOGS_PDF_DOWNLOAD";
export const GET_REPORT_LOGS_EXCEL_DOWNLOAD = "GET_REPORT_LOGS_EXCEL_DOWNLOAD";
export const GET_REPORT_LOGS_DOWNLOAD_SUCCESS =
  "GET_REPORT_LOGS_DOWNLOAD_SUCCESS";
export const GET_REPORT_LOGS_DOWNLOAD_FAILURE =
  "GET_REPORT_LOGS_DOWNLOAD_FAILURE";

// Contract History
export const GET_CONTRACT_HISTORY_SUCCESS = "GET_CONTRACT_HISTORY_SUCCESS";
export const GET_CONTRACT_HISTORY = "GET_CONTRACT_HISTORY";

export const GET_ORDER_SNAPSHOT = "GET_ORDER_SNAPSHOT";
export const GET_ORDER_SNAPSHOT_SUCCESS = "GET_ORDER_SNAPSHOT_SUCCESS";
export const GET_ORDER_SNAPSHOT_FAILURE = "GET_ORDER_SNAPSHOT_FAILURE";

export const GET_ORDER_VALUE_ANALYTICS = "GET_ORDER_VALUE_ANALYTICS";
export const GET_ORDER_VALUE_ANALYTICS_SUCCESS =
  "GET_ORDER_VALUE_ANALYTICS_SUCCESS";
export const GET_ORDER_VALUE_ANALYTICS_FAILURE =
  "GET_ORDER_VALUE_ANALYTICS_FAILURE";

export const GET_ASSET_ANALYTICS = "GET_ASSET_ANALYTICS";
export const GET_ASSET_ANALYTICS_SUCCESS = "GET_ASSET_ANALYTICS_SUCCESS";
export const GET_ASSET_ANALYTICS_FAILURE = "GET_ASSET_ANALYTICS_FAILURE";

export const GET_CUST_TRANS_ANALYTICS = "GET_CUST_TRANS_ANALYTICS";
export const GET_CUST_TRANS_ANALYTICS_SUCCESS =
  "GET_CUST_TRANS_ANALYTICS_SUCCESS";
export const GET_CUST_TRANS_ANALYTICS_FAILURE =
  "GET_CUST_TRANS_ANALYTICS_FAILURE";

export const GET_PROREV_ANALYTICS = "GET_PROREV_ANALYTICS";
export const GET_PROREV_ANALYTICS_SUCCESS = "GET_PROREV_ANALYTICS_FAILURE";
export const GET_PROREV_ANALYTICS_FAILURE = "GET_PROREV_ANALYTICS_FAILURE";

// VENDOR
export const UPDATE_VENDOR_DETAIL = "UPDATE_VENDOR_DETAIL";
export const GET_VENDOR_PROFILE_IMAGE = "GET_VENDOR_PROFILE_IMAGE";
