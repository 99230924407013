import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/objectMapper";

const initialState = {
  loading: false, orderLoading: false,
  assetLoading: false, cusTranLoading: false,
  proRevLoading: false
};

const GetOrderSnapshot = (state, action) => {
  return updateObject(state, {
    snapshotPayload: action.payload,
    snapshotResult: action.result,
    snapShotCode: action.code,
    loading: false
  });
};

const GetOrverValueAnalytics = (state, action) => {
  return updateObject(state, {
    orderPayload: action.payload,
    orderResult: action.result,
    orderCode: action.code,
    orderLoading: false
  })
}

const GetAssetAnalytics = (state, action) => {
  return updateObject(state, { assetPayload: action.payload, assetLoading: false });
}

const GetCusTransAnalytics = (state, action) => {
  return updateObject(state, { cusTransPayload: action.payload, cusTranLoading: false });
}

const GetProRevAnalytics = (state, action) => {
  const { payload } = action;
  return updateObject(state, { proRevPayload: payload, proRevLoading: false });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDER_SNAPSHOT_SUCCESS || actionTypes.GET_ORDER_SNAPSHOT_FAILURE:
      return GetOrderSnapshot(state, action);
    case actionTypes.GET_ORDER_SNAPSHOT:
      return { ...state, loading: true };

    case actionTypes.GET_ORDER_VALUE_ANALYTICS_SUCCESS || actionTypes.GET_ORDER_VALUE_ANALYTICS_FAILURE:
      return GetOrverValueAnalytics(state, action);
    case actionTypes.GET_ORDER_VALUE_ANALYTICS:
      return { ...state, orderLoading: true }

    case actionTypes.GET_ASSET_ANALYTICS:
      return { ...state, assetLoading: true }
    case actionTypes.GET_ASSET_ANALYTICS_SUCCESS || actionTypes.GET_ASSET_ANALYTICS_FAILURE:
      return GetAssetAnalytics(state, action);

    case actionTypes.GET_CUST_TRANS_ANALYTICS_SUCCESS || actionTypes.GET_CUST_TRANS_ANALYTICS_FAILURE:
      return GetCusTransAnalytics(state, action);
    case actionTypes.GET_CUST_TRANS_ANALYTICS:
      return { ...state, cusTranLoading: true }

    case actionTypes.GET_PROREV_ANALYTICS_SUCCESS || actionTypes.GET_PROREV_ANALYTICS_FAILURE:
      return GetProRevAnalytics(state, action);
    case actionTypes.GET_PROREV_ANALYTICS:
      return { ...state, proRevLoading: true }
    default:
      return state;
  }
}

export default reducer;
