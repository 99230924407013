import { put } from "redux-saga/effects";
import http from "../../services/reportLogService";
import * as actions from "../actions/index";
import * as notification from "../../utils/notificationHelper";

const {
  getReportLogsExcelStart,
  getReportLogsPDFStart,
  getReportLogsDownloadSuccess,
  getReportLogsDownloadFail
} = actions

export function* fetchReportLogsSaga(action) {
  yield put(actions.getReportLogsStart());
  try {
    const response = yield http.getReportLogs(action.payload);

    if (response.code && response.code !== 0) {
      notification.DisplayNotification("error", "Error", response.description);
    } else {
      yield put(actions.getReportLogsSuccess(response));
    }
    
  } catch (error) {
    notification.DisplayNotification(
      "error",
      "Error",
      "Unable to fetch reports."
    );
    yield put(actions.getReportLogsFail(error));
  }
}

export function* fetchReportLogsPDFDownloadSaga(action) {
  yield put(getReportLogsPDFStart());
  try {
    const response = yield http.getReportLogsPDFDownload(action.payload);

    if (response.code && response.code !== 0) {
      notification.DisplayNotification("error", "Error", response.description);
    } else {
      yield put(getReportLogsDownloadSuccess(response));
    }
    
  } catch (error) {
    notification.DisplayNotification(
      "error",
      "Error",
      "Unable to download reports."
    );
    yield put(getReportLogsDownloadFail(error));
  }
}


export function* fetchReportLogsExcelDownloadSaga(action) {
  yield put(getReportLogsExcelStart());
  try {
    const response = yield http.getReportLogsExcelDownload(action.payload);

    if (response.code && response.code !== 0) {
      notification.DisplayNotification("error", "Error", response.description);
    } else {
      yield put(getReportLogsDownloadSuccess(response));
    }
    
  } catch (error) {
    notification.DisplayNotification(
      "error",
      "Error",
      "Unable to download reports."
    );
    yield put(getReportLogsDownloadFail(error));
  }
}


