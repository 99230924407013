import {put} from "redux-saga/effects";

import ordService from "../../services/orderService";
import vendorService from "../../services/vendorService";
import authService from "../../services/authService";
import * as notification from "../../utils/notificationHelper";
import * as errorHandler from "../../utils/globalErrorHandler";
import * as actions from "../actions/index";

export function* fetchProductsSaga(action) {
  yield put(actions.pageStartLoading());
  try {
    const response = yield vendorService.getVendorProducts(action.payload);
    yield put(actions.fetchProductsSuccess({payload: response.payload}));
  } catch (error) {
    errorHandler.handleUnExpectedError(error);
  }
  yield put(actions.pageStopLoading());
}
export function* updateVendorSaga(action) {
  yield put(actions.pageStartLoading());
  try {
    const response = yield vendorService.updateVendorProfile(action.payload);
    if (response.code === 1) {
      notification.DisplayNotification(
        "success",
        "Success",
        response.description
      );
      yield put(actions.closeSideBar());
    } else
      notification.DisplayNotification("error", "Error", response.description);
  } catch (error) {
    errorHandler.handleUnExpectedError(error);
  }
  yield put(actions.pageStopLoading());
}
export function* createProductSaga(action) {
  yield put(actions.sideBarStartLoading());
  try {
    const response = yield vendorService.createVendorProduct(action.payload);
    if (response.code === 1) {
      notification.DisplayNotification(
        "success",
        "Success",
        response.description
      );
      yield put(actions.closeSideBar());
    } else
      notification.DisplayNotification("error", "Error", response.description);
  } catch (error) {}
  yield put(actions.sideBarStopLoading());
  yield put(actions.fetchProducts({page: 1, pageSize: 10, searchKeyword: ""}));
}

export function* fetchOrdersSaga(action) {
  yield put(actions.fetchOrdersStart());
  try {
    const response = yield ordService.getOrders(action.payload);

    if (response.code && response.code !== 0) {
    }
    const fetchedOrders = response;
    yield put(actions.fetchOrdersSuccess(fetchedOrders));
  } catch (error) {
    yield put(actions.fetchOrdersFail(error));
  }
}

export function* fetchContractDetailsSaga(action) {
  yield put(actions.pageStartLoading());
  try {
    const response = yield vendorService.getContractDetails(action.id);
    if (response.code === 1 || response.code === 0)
      yield put(actions.fetchContractDetailsSuccess(response.payload));
    else
      notification.DisplayNotification("error", "Error", response.description);
  } catch (error) {}
  yield put(actions.pageStopLoading());
}

export function* fetchCustomerSaga(action) {
  yield put(actions.pageStartLoading());
  try {
    const response = yield vendorService.getVendorCustomersPriceList(
      action.payload
    );
    yield put(actions.fetchCustomerSuccess({payload: response.payload, totalCount: response.totalCount}));
  } catch (error) {}
  yield put(actions.pageStopLoading());
}

export function* updateCustomerStatusSaga(action) {
  yield put(actions.pageStartLoading());
  try {
    const response = yield vendorService.updateContractStatus(action.payload);
    if (response.code === 1 || response.code === 0) {
      yield put(actions.updateContractStatusSuccess(action.payload));
      notification.DisplayNotification(
        "success",
        "Success",
        "Contract successfully updated"
      );
    } else
      notification.DisplayNotification("error", "Error", response.description);
  } catch (error) {}
  yield put(actions.pageStopLoading());
}

export function* uploadVendorUser(action) {
  try {
    yield put(actions.sideBarStartLoading());
    const uploadData = action.payload;

    const response = yield vendorService.uploadVendorUsers(uploadData);

    if (response && response.code >= 0) {
      notification.DisplayNotification(
        "success",
        "Success",
        response.description
      );
      yield put(actions.uploadVendorSuccess(response.payload));
    } else
      notification.DisplayNotification("error", "Error", response.description);
    yield put(actions.sideBarStopLoading());
  } catch (error) {}
}
export function* getDashboardDataSaga(action) {
  try {
    yield put(actions.pageStartLoading());

    let {startDate, endDate} = action.payload;
    let response = null;

    let formattedStartDate = startDate.format("YYYY/MM/DD");
    let formattedEndDate = endDate.format("YYYY/MM/DD");

    if (authService.isVendorAdmin())
      response = yield vendorService.getAdminDashboardData(
        formattedStartDate,
        formattedEndDate
      );
    if (authService.isVendorProcurement())
      response = yield vendorService.getProcDashboardData(
        formattedStartDate,
        formattedEndDate
      );
    if (authService.isVendorLogistics())
      response = yield vendorService.getLogisticDashboardData(
        formattedStartDate,
        formattedEndDate
      );

    if (
      response[0] &&
      response[0].code >= 0 &&
      response[1] &&
      response[1].code >= 0
    ) {
      yield put(
        actions.setDashboardData({
          ...response[0].payload,
          ...response[1].payload,
        })
      );
    } else {
      yield put(actions.setDashboardData({...response[0].payload}));
    }

    yield put(actions.pageStopLoading());
  } catch (error) {
    yield put(actions.pageStopLoading());
  }
}

export function* getDeliveryVendorSaga(action) {
  try {
    yield put(actions.pageStartLoading());
    const response = yield ordService.getDeliveryOrders(action.payload);

    if (response && response.code >= 0)
      yield put(actions.fetchDeliveryOrderSuccess(response.payload));

    yield put(actions.pageStopLoading());
  } catch (ex) {
    yield put(actions.pageStopLoading());
  }
}

export function* updateCustomerPriceSaga(action) {
  try {
    yield put(actions.pageStartLoading());
    const response = yield vendorService.setCustomerProductPrice(
      action.payload
    );

    yield put(actions.updateCustomerSuccess(action.payload));

    if (response.code === 1 || response.code === 0) {
      notification.DisplayNotification(
        "success",
        "Success",
        "Contract successfully updated"
      );
    } else
      notification.DisplayNotification("error", "Error", response.description);
  } catch (error) {}
  yield put(actions.pageStopLoading());
}

export function* fetchContractHistory(action) {
  yield put(actions.pageStartLoading());
  try {
    const response = yield vendorService.getContractHistory(action.payload);
    yield put(actions.fetchContractHistorySuccess({ response}));
  } catch (error) {
    errorHandler.handleUnExpectedError(error);
  }
  yield put(actions.pageStopLoading());
}
