import React from "react";
import TextField from "@material-ui/core/TextField";

const UploadInput = ({
  name,
  label,
  error,
  extraClass,
  classes,
  value,
  ...rest
}) => {
  return (
    <React.Fragment>
      <TextField
        type="file"
        error={error ? true : false}
        label={label}
        name={name}
        id={name}
        {...rest}
        margin="dense"
        style={{ width: "100%" }}
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
      />
      {error && <small>{error}</small>}
    </React.Fragment>
  );
};

export default UploadInput;
