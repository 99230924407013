import http from "./httpService";
import * as config from "../config/apiConfig";
import queryString from "query-string";

export function getVendorUsers(pageConfig) {
  // const { page, pageSize, searchBy, to, from } = pageConfig;
  return http.get(
    `${
      config.BASE_AUTH_URL
    }/api/Vendor/ListVendorUsersByVendor?${queryString.stringify(pageConfig)}`
  );
}

export function getContractHistory({ contractId, pageConfig }) {
  return http.get(
    `${
      config.BASE_ORDER_URL
    }/api/Vendor/GetContractHistory/${contractId}?${queryString.stringify(
      pageConfig
    )}`
  );
}

export function getVendorUserDetails(userId) {
  return http.get(
    `${config.BASE_AUTH_URL}/api/Vendor/getVendorUserDetail?userId=${userId}`
  );
}

export function getVendorInfo() {
  return http.get(`${config.BASE_AUTH_URL}/api/Vendor/GetVendorInfo`);
}

export function activateVendorOrderProfile() {
  return http.get(`${config.BASE_AUTH_URL}/api/Vendor/activateorderprofile`);
}

export function getTrucksForVendors(pageConfig) {
  return http.get(
    `${
      config.BASE_AUTH_URL
    }/api/Vendor/ListTrucksandDriversforVendors?${queryString.stringify(
      pageConfig
    )}`
  );
}

export function getVendorProducts(pageConfig) {
  return http.get(
    `${config.BASE_ORDER_URL}/api/vendor/listproduct?${queryString.stringify(
      pageConfig
    )}`
  );
}

export function getVendorCustomersPriceList(pageConfig) {
  return http.get(
    `${
      config.BASE_ORDER_URL
    }/api/vendor/getcontractlist?${queryString.stringify(pageConfig)}`
  );
}

export function setCustomerProductPrice(data) {
  return http.post(
    `${config.BASE_ORDER_URL}/api/vendor/UpdateContractSetting`,
    data
  );
}

export function createVendorProduct(model) {
  return http.post(`${config.BASE_ORDER_URL}/api/vendor/newproduct`, model);
}

export function getContractDetails(id) {
  return http.get(
    `${config.BASE_ORDER_URL}/api/vendor/getcontractdetail?contractid=${id}`
  );
}

export function activateVendorAccount(vendorId) {
  return http.get(
    `${config.BASE_AUTH_URL}/api/vendor/activateorderprofile?vendorid=${vendorId}`
  );
}

export function updateContractStatus(contract) {
  return http.post(
    `${config.BASE_ORDER_URL}/api/vendor/updatecontractstatus`,
    contract
  );
}

export function getAdminDashboardData(from, to) {
  return Promise.all([
    http.get(
      `${
        config.BASE_ORDER_URL
      }/api/vendorreport/getvendordashboard?${queryString.stringify({
        from,
        to,
      })}`
    ),
    http.get(`${config.BASE_AUTH_URL}/api/Vendor/GetTruckDriverStatistics`),
  ]);
}

export function getLogisticDashboardData(from, to) {
  return Promise.all([
    http.get(
      `${
        config.BASE_ORDER_URL
      }/api/vendorreport/getlogisticsdashboard?${queryString.stringify({
        from,
        to,
      })}`
    ),
    http.get(`${config.BASE_AUTH_URL}/api/Vendor/GetTruckDriverStatistics`),
  ]);
}

export function getProcDashboardData(from, to) {
  return Promise.all([
    http.get(
      `${
        config.BASE_ORDER_URL
      }/api/vendorreport/getprocurementdashboard?${queryString.stringify({
        from,
        to,
      })}`
    ),
    http.get(`${config.BASE_AUTH_URL}/api/Vendor/GetTruckDriverStatistics`),
  ]);
}

export function getAdminGraphData(graphConfig) {
  graphConfig.from = graphConfig.from.format("YYYY/MM/DD");
  graphConfig.to = graphConfig.to.format("YYYY/MM/DD");
  return http.get(
    `${
      config.BASE_ORDER_URL
    }/api/vendorreport/GetVendorDashboard?${queryString.stringify(graphConfig)}`
  );
}
export function getProcGraphData(graphConfig) {
  graphConfig.from = graphConfig.from.format("YYYY/MM/DD");
  graphConfig.to = graphConfig.to.format("YYYY/MM/DD");
  return http.get(
    `${
      config.BASE_ORDER_URL
    }/api/vendorreport/procurementgraph?${queryString.stringify(graphConfig)}`
  );
}

export function getProcCustomerGraphData(graphConfig) {
  graphConfig.from = graphConfig.from.format("YYYY/MM/DD");
  graphConfig.to = graphConfig.to.format("YYYY/MM/DD");
  return http.get(
    `${
      config.BASE_ORDER_URL
    }/api/vendorreport/ProcurementGraphTop5Customer?${queryString.stringify(
      graphConfig
    )}`
  );
}

export function getLogisticsTop5DriverGraph(graphConfig) {
  graphConfig.from = graphConfig.from.format("YYYY/MM/DD");
  graphConfig.to = graphConfig.to.format("YYYY/MM/DD");
  return http.get(
    `${
      config.BASE_ORDER_URL
    }/api/vendorreport/LogisticsTop5DriverGraph?${queryString.stringify(
      graphConfig
    )}`
  );
}

export function getLogisticsTop5FleetGraph(graphConfig) {
  graphConfig.from = graphConfig.from.format("YYYY/MM/DD");
  graphConfig.to = graphConfig.to.format("YYYY/MM/DD");
  return http.get(
    `${
      config.BASE_ORDER_URL
    }/api/vendorreport/LogisticsTop5FleetGraph?${queryString.stringify(
      graphConfig
    )}`
  );
}

export function uploadVendorUsers(payload) {
  const headerConfig = {
    headers: {
      "Content-Type": "application/form-data",
    },
  };
  return http.post(
    `${config.BASE_AUTH_URL}/api/userprofiling/uploadVendor`,
    payload,
    headerConfig
  );
}

export function updateVendorProfile(payload) {
  const headerConfig = {
    headers: {
      "Content-Type": "application/json-patch+json",
    },
  };
  return http.post(
    `${config.BASE_ORDER_URL}/api/Vendor/UpdateVendor`,
    payload,
    headerConfig
  );
}

export function getVendorLogo() {
  return http.get(`${config.BASE_ORDER_URL}/api/Vendor/GetVendorInfo`);
}

export default {
  getVendorUsers,
  getVendorInfo,
  getVendorProducts,
  createVendorProduct,
  getVendorCustomersPriceList,
  setCustomerProductPrice,
  getContractDetails,
  updateContractStatus,
  activateVendorAccount,
  getTrucksForVendors,
  getAdminDashboardData,
  getProcDashboardData,
  getLogisticDashboardData,
  uploadVendorUsers,
  getProcGraphData,
  getVendorUserDetails,
  getLogisticsTop5DriverGraph,
  getLogisticsTop5FleetGraph,
  getProcCustomerGraphData,
  getAdminGraphData,
  getContractHistory,
  updateVendorProfile,
};
