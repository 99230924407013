import analyticService from './../../services/analyticService';
import {put} from "redux-saga/effects";
import * as actions from "../actions/index";


export function* fetchOrderSnaphostSaga({ vendorId }) {
    // yield put(actions.GetOrderSnapshotStart());
    try {
        const response = yield analyticService.getOrderSnapshotService({vendorId})
  
      if (response.code && response.code !== 0) { 
      }
    //   const fetchedOrders = response;
      yield put(actions.GetOrderSnapshot(response));
    } catch (error) {
      yield put(actions.GetOrderSnapshotFail(error));
    }
  }

  export function* fetchOrderValueAnalyticsSaga({ vendorId, period }) {
     try {
      const response = yield analyticService.OrderValueService({vendorId, period});
      yield put(actions.GetOrderValueAnalytics(response));
     } catch (error) {
       yield put(actions.GetOrderValueAnalyticsFail(error));
     }
  }

  export function* fetchAssetAnalytics({vendorId}) {
    try {
      const response = yield analyticService.GetAssetUtilizationService({vendorId})
      yield put(actions.GetAssetAnalytics(response));
     } catch (error) {
       yield put(actions.GetAssetAnalyticsFail(error));
     }
  }

  export function* fetchCusTransAnalyticsSaga({vendorId, period}) {
    try {
      const response = yield analyticService.TopCustomerAndRecentOrderService({vendorId, period})
      yield put(actions.GetCusTranAnalytics(response));
     } catch (error) {
       yield put(actions.GetCusTranAnalyticsFail(error));
     }
  }

  export function* fetchProductAndRevenueAnalyticsSaga({vendorId, period}) {
    try {
      const response = yield analyticService.RevenueSnapShotService({vendorId, period})
      yield put(actions.GetProRevAnalytics(response));
     } catch (error) {
       yield put(actions.GetProRevAnalyticsFail(error));
     }
  }