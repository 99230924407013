import * as notificationHelper from "./notificationHelper";

export function handleUnExpectedError(error) {
  if (error.error_description) {
    notificationHelper.DisplayNotification(
      "error",
      "Error",
      error.error_description
    );
  } else notificationHelper.DisplayNotification("error", "Error", error);
}
