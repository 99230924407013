import moment from "moment";

export const encodeBase64 = (data) => { return data? Buffer.from(data, 'utf8').toString('base64'): ''; }

export const decodeBase64 = (data) => { return data? Buffer.from(data, 'base64').toString('utf8') : '' }

export const formatDate = value => value== null ? "NA" : moment(new Date(value)).format("LL");

export const combineRangeDates = (start, end) => {
  return (
    moment(start).format("MMMM D, YYYY") +
    " - " +
    moment(end).format("MMMM D, YYYY")
  );
};

export const getRandomRgba = () => {
  var x = Math.floor(Math.random() * 256);
  var y = Math.floor(Math.random() * 256);
  var z = Math.floor(Math.random() * 256);
  var bgColor = "rgb(" + x + "," + y + "," + z + ")";
  return bgColor;
};

export const roleTextFormatter = (role) => {
  if(role) {
    if(role.toLowerCase() === "vendor_admin") return "Admin";
    return role;
  }
}

export const dateRangeOptFormatter = (option) => {
  if (option === 0) return "Custom";
  if (option === 1) return "Daily";
  if (option === 2) return "Weekly";
  if (option === 3) return "Monthly";
}

export const calcDate = (dateObj) => {
  let d = dateObj ? new Date(dateObj) : new Date();
  var options = {
      weekday: undefined,
      year: "numeric",
      month: "short",
      day: "numeric",
  };
  return d.toLocaleDateString("en-US", options);
};

export const calcTime = (dateObj) =>{
  let d = dateObj ? new Date(dateObj) : '';
  if(d){
    d = d.toLocaleTimeString("en-US", { hour: 'numeric', minute: 'numeric' });
  }
  return d;
}
export const convertRole = (role)=> {
  let roleValuePair = {
     SYS_ADMIN: 'System Admin',
     SUPER_ADMIN: 'Super Admin',
     VENDOR_ADMIN: 'Vendor Admin',
     LOGISTIC: 'Logistics User',
     PROCUREMENT: 'Procurement User',
     FLIT: 'Fleet Manager',
     DRIVER: 'Driver User',
     CUSTOMER_ADMIN: 'Corporate Admin',
     CUSTOMER_USER: 'Corporate User',
     VENDOR_SUPER_ADMIN: 'Vendor Super Admin',
  }

  if(role) {
      return roleValuePair[role];
  }
  else return '';
}

export const dateDifference=(date1, date2)=>{
  return moment(date1).diff(moment(date2));
}

export const formatString = (str) => {
  let pr = str.toLowerCase();
  return pr.charAt(0).toUpperCase() + pr.slice(1);
}