/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import ProductSwitch from "./ProductSwitch";
import Wrench from '../assets/images/wrench.svg';
import Lock from '../assets/images/lock.svg';
import Logo from '../assets/images/logo-new.svg';
import { getTokenInfo } from "../utils/localStorageUtil";

export default class Header extends React.Component {
   
    render() {
        const { isOpen, handleDrawerOpen, handleDrawerClose } = this.props;
        const LogedInUser = getTokenInfo();

        return (
            <React.Fragment>
                <div id="" className="navbar-ag">
                    <header className="isw-nav--header" style={{ zIndex: 1001 }}>
                        <div className="isw-nav--pad" 
                         style={{ justifyContent:`${isOpen? 'right': 'space-between'}`}}
                        >
                            <div className="isw-logo-menu">
                                {/* <div className="logo"></div> */}
                                {/* {isOpen? <div>
                                    <div
                                        className="user-avatar">
                                        {LogedInUser && LogedInUser.firstName ? LogedInUser.firstName[0] : 'V'}
                                        {LogedInUser && LogedInUser.lastName ? LogedInUser.lastName[0] : 'A'}
                                        </div>
                                </div>: <></>} */}
                               
                                <div className="isw-nav-button">
                                    {isOpen
                                    ? <button id="menu-close" className="menu-btn" onClick={handleDrawerClose} 
                                        style={{
                                            display: "block",
                                            height: `${24}px`
                                        }}>
                                        <i className="material-icons">close</i>
                                    </button>

                                    : <button id="menu-reveal" className="menu-btn" onClick={handleDrawerOpen}>
                                        <i className="material-icons">menu</i>
                                    </button>}
                                </div>
                                {isOpen ? <></> : <img className="nav-logo" src={Logo} alt="logo"/> }
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="isw-image" style={{ maxWidth: "2.1rem", marginRight: "1rem" }}></div>
                                
                                <div className="dropdown dropdown-toggle">
                                    {/* <a
                                        href="#"
                                        role="button"
                                        id="dropUserNav"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{ color: '#00425f', fontSize: "14px" }}
                                    >
                                        <span className="d-xs-none d-sm-none d-md-inline font-weight-bold mr-2" onClick={this.props.onCancel}>
                                            Hi {localStorage.getItem("firstName") || "Vendor"}
                                        </span>
                                        <UserSvg />
                                    </a> */}
                                    <div onClick={this.props.onCancel}
                                        role="button"
                                        id="dropUserNav"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        className="user-avatar">
                                        {LogedInUser && LogedInUser.firstName? LogedInUser.firstName[0]: 'V' }
                                        {LogedInUser && LogedInUser.lastName? LogedInUser.lastName[0]: 'A' }</div>
                                    <div className="dropdown-menu dropdown-menu-right topbar-modal" aria-labelledby="dropUserNav">
                                        {/* <a className="dropdown-item" href="#">
                                            <p className="mb-0" style={{ fontSize: '13px' }}>{localStorage.getItem("email")}</p>
                                            <small style={{ fontSize: '13px' }}>{convertRole(localStorage.getItem("role"))}</small>
                                        </a>
                                        <div className="dropdown-divider" />
                                        <span
                                            onClick={this.props.handlePasswordUpdate}
                                            className="dropdown-item"
                                            href="#"
                                        >
                                            Change Password
                                        </span> */}
                                        <span
                                            onClick={this.props.handleProfileChange}
                                            className="dropdown-item"
                                            href="#"
                                        >
                                            <img alt="settings" src={Wrench}/>
                                            Settings
                                        </span>
                                        <span
                                            onClick={this.props.logout}
                                            className="dropdown-item text-danger"
                                            href="#"
                                        >
                                            <img alt="logout" src={Lock}/>
                                            Logout
                                        </span>
                                    </div>
                                </div>

                                {/* <ProductSwitch
                                    onCancel={this.props.onCancel}
                                    showShortcut={this.props.showShortcut}
                                    modules={this.props.modules || []}
                                /> */}
                            </div>
                        </div>
                    </header>
                </div>
            </React.Fragment>
        );
    }
}
