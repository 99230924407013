import { put } from "redux-saga/effects";

import http from "../../services/orderService";
import * as actions from "../actions/index";
import * as notification from "../../utils/notificationHelper";

// export function* purchaseBurgerSaga(action) {
//   yield put(actions.purchaseBurgerStart());
//   try {
//     const response = yield axios.post(
//       "/orders.json?auth=" + action.token,
//       action.orderData
//     );
//     yield put(
//       actions.purchaseBurgerSuccess(response.data.name, action.orderData)
//     );
//   } catch (error) {
//     yield put(actions.purchaseBurgerFail(error));
//   }
// }

export function* fetchOrderItemsSaga(action) {
  try {
    yield put(actions.pageStartLoading());
    const orderResponse = yield http.getOrderItems(action.config);
    if (orderResponse.code < 0) {
      notification.DisplayNotification(
        "error",
        "Error",
        orderResponse.description
      );
    } else
      yield put(
        actions.updateOrderItems({
          payload: orderResponse.payload,
          totalCount: orderResponse.totalCount
        })
      );
  } catch (error) {
    // console.log(error);
    notification.DisplayNotification(
      "error",
      "Error",
      "Unexpected error occurred."
    );
  }
  yield put(actions.pageStopLoading());
}



export function* fetchOrdersSaga(action) {
  yield put(actions.fetchOrdersStart());
  try {
    const response = yield http.getOrders(action.payload);

    if (response.code && response.code !== 0) { 
    }
    const fetchedOrders = response;
    yield put(actions.fetchOrdersSuccess(fetchedOrders));
  } catch (error) {
    yield put(actions.fetchOrdersFail(error));
  }
}
