import React, { Suspense } from "react";
import Form from "./components/common/form";
import { Route, Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import ProtectedRoute from "./components/common/protectedRoute";
import NotFound from "./pages/notfound";
import BgLoader from "./components/common/backgroundLoader";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import history from "./utils/history";
import { auth, setAuthRedirectPath } from "./store/actions/index";
import { FRONT_SSO_URL } from "./config/apiConfig";
import TempHeader from "./components/header-ag";
import TimeOut from "./helpers/timeOut";

const Account = React.lazy(() => import("./pages/account/account"));
const Admin = React.lazy(() => import("./pages/admin/admin"));
const ResetPassword = React.lazy(() => import("./pages/account/resetPassword"));

const theme = createMuiTheme({
  palette: {
    primary: { main: "#00425F" }, // Purple and green play nicely together.
    secondary: { main: "#11cb5f" }, // This is just green.A700 as hex.
  },
  typography: { useNextVariants: true },
});

class App extends Form {
  componentDidMount() {
    this.props.onAuth();
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <main role="main">
          <Suspense fallback={<BgLoader />}>
            <Router history={history}>
              <div>
                <TimeOut />
                <Switch>
                  <Route
                    path="/vendor/signin"
                    render={() => {
                      this.props.onAuth();
                      return <BgLoader message="Redirecting..." />;
                    }}
                  />

                  <Route path="/header" component={TempHeader} />

                  <Route
                    path="/vendor/denied"
                    render={() => {
                      window.location.href = FRONT_SSO_URL + "?d=isw";
                      return <BgLoader message="Redirecting..." />;
                    }}
                  />

                  <Route
                    path="/vendor/resetpassword"
                    render={(props) => <ResetPassword {...props} />}
                  />

                  <Route
                    path="/vendor/account"
                    render={(props) => <Account {...props} />}
                  />

                  <ProtectedRoute
                    path="/vendor"
                    render={(props) => <Admin {...props} />}
                  />

                  {
                    <ProtectedRoute
                      exact
                      path="/"
                      render={(props) => <Admin {...props} />}
                    />
                  }

                  <Route component={NotFound} />
                </Switch>
              </div>
            </Router>
          </Suspense>
        </main>
      </MuiThemeProvider>
    );
  }
}

export default connect(null, {
  onAuth: auth,
  onSetAuthRedirectPath: setAuthRedirectPath,
})(App);
