import http from "./httpService";
import querystring from "query-string";
import * as api from "../config/apiConfig";

export function getReportLogs(pageConfig) {
  return http.get(
    `${api.BASE_REPORT_URL}/api/v1/report/customers/order/all?${querystring.stringify(
      pageConfig
    )}`
  );
}

export function getReportLogsPDFDownload(pageConfig) {
  return http.getDownload(
    `${api.BASE_REPORT_URL}/api/v1/report/customers/order/generate/pdf?${querystring.stringify(
      pageConfig
    )}`
  );
}

export function getReportLogsExcelDownload(pageConfig) {
  return http.getDownload(
    `${api.BASE_REPORT_URL}/api/v1/report/customers/order/generate/excel?${querystring.stringify(
      pageConfig
    )}`
  );
}

export default {
  getReportLogs,
  getReportLogsPDFDownload,
  getReportLogsExcelDownload,
};
