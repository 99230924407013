import {
  GET_REPORT_LOGS,
  GET_REPORT_LOGS_FAILURE,
  GET_REPORT_LOGS_SUCCESS,
  GET_REPORT_LOGS_PDF_DOWNLOAD,
  GET_REPORT_LOGS_EXCEL_DOWNLOAD,
  GET_REPORT_LOGS_DOWNLOAD_SUCCESS,
  GET_REPORT_LOGS_DOWNLOAD_FAILURE,
} from "./actionTypes";

export const getReportLogsFail = (error) => {
  return {
    type: GET_REPORT_LOGS_FAILURE,
    error: error,
  };
};

export const getReportLogsStart = () => {
  return {
    type: GET_REPORT_LOGS,
  };
};

export const getReportLogsSuccess = (config) => {
  return {
    type: GET_REPORT_LOGS_DOWNLOAD_SUCCESS,
    config: config,
  };
};

export const getReportLogsPDFStart = () => {
  return {
    type: GET_REPORT_LOGS_PDF_DOWNLOAD,
  };
};

export const getReportLogsExcelStart = () => {
  return {
    type: GET_REPORT_LOGS_EXCEL_DOWNLOAD,
  };
};

export const getReportLogsDownloadFail = (error) => {
  return {
    type: GET_REPORT_LOGS_DOWNLOAD_FAILURE,
    error: error,
  };
};

export const getReportLogsDownloadSuccess = (config) => {
  return {
    type: GET_REPORT_LOGS_SUCCESS,
    config: config,
  };
};
