import http from "./httpService";
import querystring from "query-string";
import authService from "./authService";
import * as api from "../config/apiConfig";

//Refactor
// function getPendingOrderStatusByRole() {
//   if (authService.isVendorLogistics()) return 2;
//   return 2;
// }

function getApprovedOrderStatusByRole() {
  if (authService.isVendorLogistics()) return 6;
  return 3;
}

export function getOrders(pageConfig) {
  return http.get(
    `${api.BASE_ORDER_URL}/api/Vendor/GetVendorsOrders?${querystring.stringify(
      pageConfig
    )}`
  );
}

export function getOrderItems(pageConfig) {
  return http.get(
    `${
      api.BASE_ORDER_URL
    }/api/Vendor/GetAllOrdersItemDetails?${querystring.stringify(pageConfig)}`
  );
}

export function getPendingOrders(pageConfig) {
  // pageConfig.orderStatus = getPendingOrderStatusByRole();
  return http.get(
    `${
      api.BASE_ORDER_URL
    }/api/Vendor/GetVendorsPendingOrders?${querystring.stringify(pageConfig)}`
  );
}

export function getApprovedOrders(pageConfig) {
  pageConfig.orderStatus = getApprovedOrderStatusByRole();
  return http.get(
    `${api.BASE_ORDER_URL}/api/Vendor/GetVendorsOrders?${querystring.stringify(
      pageConfig
    )}`
  );
}

export function getDeliveryOrders(pageConfig) {
  return http.get(
    `${
      api.BASE_ORDER_URL
    }/api/Delivery/GetDeliveryVendor?${querystring.stringify(pageConfig)}`
  );
}

export function getDeliveryDetails(deliveryId) {
  return http.get(
    `${api.BASE_ORDER_URL}/api/Delivery/GetDeliveryDetail?deliveryId=${deliveryId}`
  );
}

export function getUsers(pageConfig) {
  const { pageIndex, pageSize } = pageConfig;
  return http.get(
    `${api.BASE_AUTH_URL}/api/Vendor/ListVendorUsersByVendor?PageIndex=${pageIndex}&PageSize=${pageSize}`
  );
}

export function getFleetOwners(pageConfig) {
  const { pageIndex, pageSize } = pageConfig;
  return http.get(
    `${api.BASE_AUTH_URL}/api/Vendor/ListFleetOwnersforVendor?PageIndex=${pageIndex}&PageSize=${pageSize}`
  );
}
export function approveOrder(data) {
  // data.status = getApprovedOrderStatusByRole();
  return http.post(
    `${api.BASE_ORDER_URL}/api/Vendor/VendorApproveOrders`,
    data
  );
}

export function completeOrder(data) {
  // data.status = getApprovedOrderStatusByRole();
  return http.post(
    `${api.BASE_ORDER_URL}/api/Delivery/UpdateDeliveryStatus`,
    data
  );
}

export function scheduleOrder(data) {
  return http.post(`${api.BASE_ORDER_URL}/api/Delivery/AddToSchedule`, data);
}

export function createVendor(data) {
  return http.post(
    `${api.BASE_AUTH_URL}/api/UserProfiling/CreateVendorUser`,
    data
  );
}

export function getDashboardData() {
  return http.all([
    http.get(`${getOrderByStatus(2)}`),
    http.get(`${getOrderByStatus(3)}`),
  ]);
}

function getOrderByStatus(status) {
  let url = `${api.BASE_ORDER_URL}/api/Vendor/GetVendorsOrders?pageIndex=1&pageSize=20`;
  return url + `&status=${status}`;
}

export function getOrderdetails(pageConfig, orderId) {
  const { pageIndex, pageSize } = pageConfig;
  return http.get(
    `${api.BASE_ORDER_URL}/api/Vendor/GetVendorsOrderswithItems?orderId=${orderId}&PageIndex=${pageIndex}&PageSize=${pageSize}`
  );
}

export function getVendors(pageConfig, orderId) {
  const { pageIndex, pageSize, searchKeyword } = pageConfig;
  return http.get(
    `${api.BASE_AUTH_URL}/api/Vendor/ListVendorUsersByVendor?PageIndex=${pageIndex}&PageSize=${pageSize}&SearchKeyword=${searchKeyword}`
  );
}

export default {
  getOrders,
  getPendingOrders,
  getOrderdetails,
  approveOrder,
  createVendor,
  getVendors,
  getApprovedOrders,
  getOrderItems,
  scheduleOrder,
  getDeliveryOrders,
  getDeliveryDetails,
  getDashboardData,
  completeOrder,
};
