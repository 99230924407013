import { takeEvery, all } from "redux-saga/effects";

import * as actionTypes from "../actions/actionTypes";
import {
  logoutSaga,
  checkAuthTimeoutSaga,
  authUserSaga,
  authCheckStateSaga,
  changePasswordSaga,
  updateUserProfileSaga
} from "./auth";
import {
  fetchOrdersSaga,
  fetchProductsSaga,
  createProductSaga,
  fetchCustomerSaga,
  updateCustomerPriceSaga,
  fetchContractDetailsSaga,
  updateCustomerStatusSaga,
  getDashboardDataSaga,
  getDeliveryVendorSaga,
  uploadVendorUser,
  fetchContractHistory,
  updateVendorSaga
} from "./vendor";
import { fetchOrderItemsSaga } from "./order";
import { fetchReportLogsSaga } from "./reportLogs";
import { fetchAssetAnalytics as fetchAssetAnalyticsSaga, fetchCusTransAnalyticsSaga, fetchOrderSnaphostSaga, fetchOrderValueAnalyticsSaga, fetchProductAndRevenueAnalyticsSaga } from "./analytics";

export function* watchAuth() {
  yield all([
    takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga),
    takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
    takeEvery(actionTypes.AUTH_USER, authUserSaga),
    takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga),
    takeEvery(actionTypes.AUTH_CHANGE_PASSWORD, changePasswordSaga),
    takeEvery(actionTypes.AUTH_UPDATE_USERPROFILE, updateUserProfileSaga)
  ]);
}

export function* watchOrder() {
  //   yield takeLatest(actionTypes.APPROVE_ORDER, approveOrderSaga);
  yield takeEvery(actionTypes.FETCH_ORDERS, fetchOrdersSaga);
  yield takeEvery(actionTypes.GET_REPORT_LOGS, fetchReportLogsSaga);
  yield takeEvery(actionTypes.FETCH_ORDER_ITEMS, fetchOrderItemsSaga);
  yield takeEvery(actionTypes.FETCH_PRODUCTS, fetchProductsSaga);
  yield takeEvery(actionTypes.CREATE_PRODUCT, createProductSaga);
  yield takeEvery(actionTypes.FETCH_CUSTOMERS, fetchCustomerSaga);
  yield takeEvery(actionTypes.UPDATE_CUSTOMER, updateCustomerPriceSaga);
  yield takeEvery(actionTypes.UPDATE_CONTRACT_STATUS, updateCustomerStatusSaga);
  yield takeEvery(actionTypes.FETCH_CONTRACT_DETAILS, fetchContractDetailsSaga);
  yield takeEvery(actionTypes.FETCH_DASHBOARD_DATA, getDashboardDataSaga);
  yield takeEvery(actionTypes.FETCH_DELIVERY_ORDERS, getDeliveryVendorSaga);
  yield takeEvery(actionTypes.ACCOUNT_CREATE_BULK_USER, uploadVendorUser);
  yield takeEvery(actionTypes.GET_CONTRACT_HISTORY, fetchContractHistory);
  yield takeEvery(actionTypes.UPDATE_VENDOR_DETAIL,updateVendorSaga )
}

export function* watchAnalytics() {
  yield takeEvery(actionTypes.GET_ORDER_SNAPSHOT, fetchOrderSnaphostSaga);
  yield takeEvery(actionTypes.GET_ORDER_VALUE_ANALYTICS, fetchOrderValueAnalyticsSaga);
  yield takeEvery(actionTypes.GET_ASSET_ANALYTICS, fetchAssetAnalyticsSaga);
  yield takeEvery(actionTypes.GET_CUST_TRANS_ANALYTICS, fetchCusTransAnalyticsSaga);
  yield takeEvery(actionTypes.GET_PROREV_ANALYTICS, fetchProductAndRevenueAnalyticsSaga);
}
