import React from "react";

const Select = ({ name, label, options, error, ...rest }) => {
  return (
    <div className="form-select">
      <select
        // error={error ? true : false}
        name={name}
        id={name}
        {...rest}
        className="select-text"
      >
        <option value="" />
        {options.map(option => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </select>
      <span className="select-highlight" />
      <span className="select-bar" />
      <label className="select-label">{label}</label>
      {error && <small>{error}</small>}
    </div>
  );
};

export default Select;
