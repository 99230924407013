import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/js/bootstrap.min";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/stylesheet/app.css";
import "./assets/stylesheet/kamsi.css"
import "./assets/font/stylesheet.css";
import "./assets/icons/icons.css";
import 'react-loading-skeleton/dist/skeleton.css';
import {store} from './create-store';
// const composeEnhancers =
//   process.env.NODE_ENV === "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : null || compose;


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
