import * as actionTypes from "./actionTypes"; 

export const GetOrderSnapshotStart = (id) => {
    return { type: actionTypes.GET_ORDER_SNAPSHOT,
             vendorId: id
            }
}

export const GetOrderSnapshot = (data) => {
    return {
      type: actionTypes.GET_ORDER_SNAPSHOT_SUCCESS,
      payload: data.payload,
      totalCount: data.totalCount,
      result: data.result,
      code: data.code,
      description: data.description,
    };
};

export const GetOrderSnapshotFail = (error) =>{
    return {
        type: actionTypes.GET_ORDER_SNAPSHOT_FAILURE,
        error
    }
}

export const GetOrderValueAnalyticsStart =(id, period) =>{
    return{
        type: actionTypes.GET_ORDER_VALUE_ANALYTICS,
        vendorId: id,
        period
    };
}

export const GetOrderValueAnalytics = (data) => {
    return {
      type: actionTypes.GET_ORDER_VALUE_ANALYTICS_SUCCESS,
      payload: data.payload,
      totalCount: data.totalCount,
      result: data.result,
      code: data.code,
      description: data.description,
    };
};

export const GetOrderValueAnalyticsFail =(error) =>{
    return{
        type: actionTypes.GET_ORDER_VALUE_ANALYTICS,
        error
    };
}

export const GetAssetAnalyticsStart =(id) =>{
    return{
        type: actionTypes.GET_ASSET_ANALYTICS,
        vendorId: id,
    };
}

export const GetAssetAnalytics = (data) => {
    return {
      type: actionTypes.GET_ASSET_ANALYTICS_SUCCESS,
      payload: data.payload,
      totalCount: data.totalCount,
      result: data.result,
      code: data.code,
      description: data.description,
    };
};

export const GetAssetAnalyticsFail =(error) =>{
    return{
        type: actionTypes.GET_ASSET_ANALYTICS_FAILURE,
        error
    };
}

////
export const GetCusTranAnalyticsStart =(id, period) =>{
    return{
        type: actionTypes.GET_CUST_TRANS_ANALYTICS,
        vendorId: id,
        period,
    };
}

export const GetCusTranAnalytics = (data) => {
    return {
      type: actionTypes.GET_CUST_TRANS_ANALYTICS_SUCCESS,
      payload: data.payload,
      totalCount: data.totalCount,
      result: data.result,
      code: data.code,
      description: data.description,
    };
};

export const GetCusTranAnalyticsFail =(error) =>{
    return{
        type: actionTypes.GET_CUST_TRANS_ANALYTICS_FAILURE,
        error
    };
}

///
export const GetProRevAnalyticsStart =({id, period}) =>{
    return{
        type: actionTypes.GET_PROREV_ANALYTICS,
        vendorId: id,
        period,
    };
}

export const GetProRevAnalytics = (data) => {
    console.log('data', data);
    return {
      type: actionTypes.GET_PROREV_ANALYTICS_SUCCESS,
      payload: data.payload,
      totalCount: data.totalCount,
      result: data.result,
      code: data.code,
      description: data.description,
    };
};

export const GetProRevAnalyticsFail =(error) =>{
    return{
        type: actionTypes.GET_PROREV_ANALYTICS_FAILURE,
        error
    };
}