import axios from "axios";
import history from "../utils/history";

axios.interceptors.response.use(
  response => {
    if (typeof response === "string" || response instanceof String)
      return response;
    if (response == null) response = {};
    else response = response.data;
    if (response.payload == null) response.payload = [];
    return response;
  },
  error => {
    if (!error.response)
      return Promise.reject({
        status: 500,
        error_description: "Network error occurred"
      });

    // const expectedError =
    //   error.response &&
    //   error.response.status >= 400 &&
    //   error.response.status < 500;

    if (error.response.status === 401) {
      localStorage.clear();
      history.push("/vendor/signin");
    }

    return Promise.reject(error.response.data);
  }
);

axios.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

function setJwt(jwt) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
}

/**
 * WHY? To Download files
  You create an axios instance for downloads separately,
 because you need the "responseType"
 */

const downloadInstance = axios.create({
  responseType: "blob", // VERY IMPORTANT FOR DOWNLOADS else file will be blank
});

downloadInstance.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  all: axios.all,
  getDownload: downloadInstance.get,
  setJwt
};
