import queryString from "query-string";
import * as api from "../config/apiConfig";
import { decodeBase64 } from "../utils/utils";
import http from "./httpService";

const axios = require("axios");

function hasCorrectUCC(ucc) {
  const userCadreCode = decodeBase64(localStorage.getItem("ucc"));
  return userCadreCode === ucc;
}

export function isVendorAdmin() {
  return (
    hasCorrectUCC("VDA_01") && localStorage.getItem("role") === "VENDOR_ADMIN"
  );
}

export function isVendorProcurement() {
  return (
    hasCorrectUCC("VDP_01") ||
    ["PROCUREMENT", "VENDOR_USER"].includes(localStorage.getItem("role"))
  );
}

export function isVendorLogistics() {
  return hasCorrectUCC("VDL_01") || localStorage.getItem("role") === "LOGISTIC";
}

export function disableVendorUser({ userId, activate }) {
  return axios.post(
    `${api.BASE_AUTH_URL}/api/UserProfiling/ToggleProfileActivation`,
    {
      userId,
      activate,
    }
  );
}

export function isCurrentUserAuthenticated() {
  return localStorage.getItem("token");
}

export function resetVendorPassword(email) {
  return axios.get(
    `${api.BASE_AUTH_URL}/api/Auth/ForgotPassword?emailAddress=${email}`
  );
}

export function approveTrucknDriver(model) {
  return axios.post(
    `${api.BASE_AUTH_URL}/api/Vendor/ApproveTrucknDriver`,
    model
  );
}

export const finalizeForgotPassword = (payload) => {
  return axios.post(`${api.BASE_AUTH_URL}/api/Auth/ResetPasssword`, payload);
};

export function updateUserProfile(data) {
  return http.post(`${api.BASE_AUTH_URL}/api/userprofiling/editprofile`, data);
}

export function getUserProfileById(userId) {
  return http.get(
    `${api.BASE_AUTH_URL}/api/userprofiling/getprofile?userId=${userId}`
  );
}

export function getUserBasicProfile() {
  return http.get(`${api.BASE_AUTH_URL}/api/auth/getbasicprofile`);
}

export function getCurrentUserInfo() {
  return {
    name: localStorage.getItem("name"),
    email: localStorage.getItem("email"),
    role: localStorage.getItem("role"),
    phoneNo: localStorage.getItem("phone"),
    vendorId: localStorage.getItem("vendorId"),
    vendorNumber: localStorage.getItem("vendorNumber"),
    vendorLogoUrl: localStorage.getItem("vendorLogoUrl"),
    vendorCorridor: localStorage.getItem("vendorCorridor"),
  };
}

//comment
export function changeVendorPassword(data) {
  return axios.post(`${api.BASE_AUTH_URL}/api/auth/changepassword`, data);
}
export function vendorLogin(data) {
  const requestData = {
    username: data.userName,
    password: data.password,
    grant_type: "password",
    client_id: api.VENDOR_CLIENT_ID,
    client_secret: api.VENDOR_CLIENT_KEY,
  };
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  return axios.post(
    `${api.BASE_AUTH_URL}/api/auth/token`,
    queryString.stringify(requestData),
    config
  );
}

export default {
  isCurrentUserAuthenticated,
  resetVendorPassword,
  changeVendorPassword,
  vendorLogin,
  isVendorAdmin,
  isVendorProcurement,
  isVendorLogistics,
  getCurrentUserInfo,
  updateUserProfile,
  disableVendorUser,
  getUserProfileById,
  getUserBasicProfile,
  approveTrucknDriver,
  finalizeForgotPassword,
};
