import { notification } from "antd";

export function DisplayNotification(
  type = "success",
  title = "",
  message = ""
) {
  if (type === "success") {
    notification.success({
      message: title,
      description: message
    });
  } else {
    notification.error({
      message: title,
      description: message
    });
  }
}
