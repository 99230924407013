// const isDev = window.env.NODE_ENV != "production";
export const BASE_ORDER_URL = window.env.REACT_APP_BASE_ORDER_URL;
//  ?
// window.env.REACT_APP_BASE_ORDER_URL :
//     "https://uber-for-diesel-ag-ordermgmt-service.k8.isw.la/u4do";

export const BASE_REPORT_URL = window.env.REACT_APP_BASE_REPORT_URL;

export const FRONT_SSO_URL = window.env.REACT_APP_FRONT_SSO_URL;

export const BASE_AUTH_URL = window.env.REACT_APP_BASE_AUTH_URL;
// ?
// window.env.REACT_APP_BASE_AUTH_URL :
//     "https://uber-for-diesel-auth-service.k8.isw.la/u4da";

export const VENDOR_CLIENT_ID = window.env.REACT_APP_VENDOR_CLIENT_ID
  ? window.env.REACT_APP_VENDOR_CLIENT_ID
  : "smartfuelvendor";
// ?
// window.env.REACT_APP_VENDOR_CLIENT_ID :
//     "smartfuelvendor";

export const VENDOR_CLIENT_KEY = window.env.REACT_APP_VENDOR_CLIENT_KEY;
// ?
// window.env.REACT_APP_VENDOR_CLIENT_KEY :
//     "3B00F42D-20DA-49DE-95F4-47B9565BED18";

export const MAP_API_KEY = window.env.REACT_APP_MAP_API_KEY;
// ?
// window.env.REACT_APP_MAP_API_KEY :
//     "AIzaSyAS3ix4rVY4A-T4yPzWlEi766ycl2mY818";
