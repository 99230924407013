import * as actionTypes from "./actionTypes";

export const approveOrderSuccess = (id, orderData) => {
  return {
    type: actionTypes.APPROVE_ORDER_SUCCESS,
    orderId: id,
    orderData: orderData,
  };
};

export const fetchContractDetails = (id) => {
  return {
    type: actionTypes.FETCH_CONTRACT_DETAILS,
    id,
  };
};

export const fetchContractDetailsSuccess = (contract) => {
  return {
    type: actionTypes.FETCH_CONTRACT_DETAILS_SUCCESS,
    contract,
  };
};

export const updateContractDetails = (contract) => {
  return {
    type: actionTypes.UPDATE_CONTRACT_DETAILS,
    contract,
  };
};

export const approveOrderFail = (error) => {
  return {
    type: actionTypes.APPROVE_ORDER_FAIL,
    error: error,
  };
};

export const approveOrderStart = () => {
  return {
    type: actionTypes.APPROVE_ORDER_START,
  };
};

export const approveOrder = (orderData, token) => {
  return {
    type: actionTypes.APPROVE_ORDER,
    orderData: orderData,
    token: token,
  };
};

export const fetchOrdersSuccess = (orders) => {
  return {
    type: actionTypes.FETCH_ORDERS_SUCCESS,
    orders: orders,
  };
};

export const uploadBulkUserData = (uploadData) => {
  return {
    type: actionTypes.ACCOUNT_CREATE_BULK_USER,
    payload: uploadData,
  };
};

export const fetchOrdersFail = (error) => {
  return {
    type: actionTypes.FETCH_ORDERS_FAIL,
    error: error,
  };
};

export const fetchOrdersStart = () => {
  return {
    type: actionTypes.FETCH_ORDERS_START,
  };
};

export const fetchOrders = (config) => {
  return {
    type: actionTypes.FETCH_ORDERS,
    payload: config,
  };
};

export const fetchProducts = (config) => {
  return {
    type: actionTypes.FETCH_PRODUCTS,
    payload: config,
  };
};

export const fetchCustomers = (config) => {
  return {
    type: actionTypes.FETCH_CUSTOMERS,
    payload: config,
  };
};

export const fetchCustomerSuccess = (action) => {
  return {
    type: actionTypes.FETCH_CUSTOMERS_SUCCESS,
    customers: action.payload,
    totalCount: action.totalCount,
  };
};

export const updateCustomer = (contract) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER,
    payload: contract,
  };
};

export const updateContractStatus = (action) => {
  return {
    type: actionTypes.UPDATE_CONTRACT_STATUS,
    payload: action.payload,
  };
};

export const updateCustomerSuccess = (contract) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_SUCCESS,
    payload: contract,
  };
};

export const updateContractStatusSuccess = (contract) => {
  return {
    type: actionTypes.UPDATE_CONTRACT_STATUS_SUCCESS,
    payload: contract,
  };
};

export const fetchProductsSuccess = (action) => {
  return {
    type: actionTypes.FETCH_PRODUCTS_SUCCESS,
    payload: action.payload,
  };
};

export const createProduct = (action) => {
  return {
    type: actionTypes.CREATE_PRODUCT,
    payload: action.payload,
  };
};

export const createProductSuccess = (action) => {
  return {
    type: actionTypes.CREATE_PRODUCT_SUCCESS,
    payload: action.payload,
  };
};

export const fetchDashboardData = (startDate, endDate) => {
  return {
    type: actionTypes.FETCH_DASHBOARD_DATA,
    payload: { startDate, endDate },
  };
};

export const setDashboardData = (dashboardData) => {
  return {
    type: actionTypes.SET_DASHBOARD_DATA,
    payload: dashboardData,
  };
};

export const uploadVendorSuccess = (payload) => {
  return {
    type: actionTypes.ACCOUNT_CREATE_BULK_USER_SUCCESS,
    payload: payload,
  };
};

export const resetVendorUploadResult = () => {
  return {
    type: actionTypes.ACCOUNT_BULK_USER_RESET,
  };
};

export const fetchContractHistorySuccess = (payload) => {
  return {
    type: actionTypes.GET_CONTRACT_HISTORY_SUCCESS,
    response: { ...payload.response },
  };
};

export const fetchContractHistory = (id, pageIndex, pageSize) => {
  return {
    type: actionTypes.GET_CONTRACT_HISTORY,
    payload: { contractId: id, pageConfig: { pageIndex, pageSize } },
  };
};

export const updateVendorDetail = (payload) => {
  return {
    type: actionTypes.UPDATE_VENDOR_DETAIL,
    payload,
  };
};

export const getVendorProfileLogo = (payload) => {
  return {
    type: actionTypes.GET_VENDOR_PROFILE_IMAGE,
    payload,
  };
};
