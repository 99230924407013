import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/objectMapper";

const initialState = {
  orders: [],
  orderItems: [],
  loading: false,
  orderItemsCount: 0,
  approved: false
};

const approveOrderStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const approveOrderSuccess = (state, action) => {
  const newOrder = updateObject(action.orderData, { id: action.orderId });
  return updateObject(state, {
    loading: false,
    approved: true,
    orders: state.orders.concat(newOrder)
  });
};

const approveOrderFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const fetchOrdersStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateOrderItems = (state, action) => {
  return updateObject(state, {
    orderItems: action.payload,
    orderItemsCount: action.totalCount
  });
};

const fetchOrdersSuccess = (state, action) => {
  return updateObject(state, {
    orders: action.payload
  });
};

const fetchOrdersFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPROVE_ORDER_START:
      return approveOrderStart(state, action);
    case actionTypes.APPROVE_ORDER_SUCCESS:
      return approveOrderSuccess(state, action);
    case actionTypes.APPROVE_ORDER_FAIL:
      return approveOrderFail(state, action);
    case actionTypes.FETCH_ORDERS_START:
      return fetchOrdersStart(state, action);
    case actionTypes.FETCH_ORDERS_SUCCESS:
      return fetchOrdersSuccess(state, action);
    case actionTypes.FETCH_ORDERS_FAIL:
      return fetchOrdersFail(state, action);
    case actionTypes.UPDATE_ORDER_ITEMS:
      return updateOrderItems(state, action);
    default:
      return state;
  }
};

export default reducer;
