import React from "react";
import TextField from "@material-ui/core/TextField";

const Input = ({ name, label, error, extraClass, classes, value, ...rest }) => {
  return (
    <React.Fragment>
      <TextField
        error={error ? true : false}
        label={label}
        name={name}
        value={value}
        id={name}
        autoComplete="new-password"
        margin="dense"
        variant="filled"
        {...rest}
      />
      {error && <small style={{display: "block"}}>{error}</small>}
    </React.Fragment>
  );
};

export default Input;
