import history from "./utils/history";
import { routerMiddleware } from "react-router-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { watchAuth, watchOrder, watchAnalytics } from "./store/sagas";
import createSagaMiddleware from "redux-saga";
import authReducer from "./store/reducers/auth";
import uiReducer from "./store/reducers/ui";
import vendorReducer from "./store/reducers/vendor";
import orderReducer from "./store/reducers/order";
import analyticReducer from "./store/reducers/analytics";

let composeEnhancers = null;
if (window.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}
const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  vendor: vendorReducer,
  order: orderReducer,
  analytics: analyticReducer,
});

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
);

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchOrder);
sagaMiddleware.run(watchAnalytics);
