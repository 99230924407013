// Red for Reducers
import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/objectMapper";
// import moment from "moment";
// import { stat } from "fs";

const initialState = {
  products: [],
  customers: [],
  contract: {},
  uploadResults: [],
  dashboardData: [],
  pendingDeliveries: [],
  vendorUpdate: [],
  profileLogo: "",
};

const fetchProductSuccess = (state, action) => {
  return updateObject(state, {
    products: action.payload,
  });
};

const updateVendorDetail = (state, action) => {
  return updateObject(state, {
    vendorUpdate: action.payload,
  });
};
const fetchVendorProfileLogo = (state, action) => {
  return updateObject(state, {
    profileLogo: action.payload,
  });
};

const fetchCustomerSuccess = (state, action) => {
  return updateObject(state, {
    customers: action.customers,
    totalCount: action.totalCount,
  });
};

const fetchContractDetailsSuccess = (state, action) => {
  return updateObject(state, {
    contract: action.contract,
  });
};

const fetchDeliveryOrderSuccess = (state, action) => {
  return updateObject(state, {
    pendingDeliveries: action.payload,
  });
};

const updateCustomerSuccess = (state, action) => {
  const { payload } = action;

  let contract = Object.assign({ ...payload, ...state.contract });

  contract.unitPrice = contract.price;
  contract.contractCycle = payload.contractCycle;
  contract.isVap = payload.vapStatus === "true";
  contract.contractLimit = payload.contractLimit;
  contract.autoRenew = payload.autoRew;

  contract.startDate = new Date(payload.startDate);
  contract.endDate = new Date(payload.endDate);
  //   console.log("New Contract", contract);

  delete contract.id;

  return updateObject(state, {
    contract,
  });
};

const updateContractStatusSuccess = (state, action) => {
  const { payload } = action;

  let customers = [...state.customers];

  let customer = customers.filter((c) => c.id === payload.contractId)[0];
  customers = customers.filter((c) => c.id !== payload.contractId);
  customer.status = payload.contractStatus;
  customers = [...customers, ...[customer]];

  // console.log("Next data 2", customers);
  return updateObject(state, {
    customers,
  });
};

const createProductSuccess = (state, action) => {
  return state;
};

const setDashboardDataRed = (state, action) => {
  const { payload } = action;
  return updateObject(state, {
    dashboardData: payload,
  });
};

const uploadVendorSuccess = (state, action) => {
  const { payload } = action;
  return updateObject(state, {
    uploadResults: payload,
  });
};

const resetVendorUploadResult = (state) => {
  return updateObject(state, {
    uploadResults: [],
  });
};

const fetchContractHistory = (state, action) => {
  return updateObject(state, {
    contractHistory: action,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCTS_SUCCESS:
      return fetchProductSuccess(state, action);
    case actionTypes.CREATE_PRODUCT:
      return createProductSuccess(state, action);
    case actionTypes.UPDATE_CUSTOMER_SUCCESS:
      return updateCustomerSuccess(state, action);
    case actionTypes.UPDATE_CONTRACT_STATUS_SUCCESS:
      return updateContractStatusSuccess(state, action);
    case actionTypes.FETCH_CUSTOMERS_SUCCESS:
      return fetchCustomerSuccess(state, action);
    case actionTypes.FETCH_CONTRACT_DETAILS_SUCCESS:
      return fetchContractDetailsSuccess(state, action);
    case actionTypes.SET_DASHBOARD_DATA:
      return setDashboardDataRed(state, action);
    case actionTypes.FETCH_DELIVERY_ORDERS_SUCCESS:
      return fetchDeliveryOrderSuccess(state, action);
    case actionTypes.ACCOUNT_CREATE_BULK_USER_SUCCESS:
      return uploadVendorSuccess(state, action);
    case actionTypes.ACCOUNT_BULK_USER_RESET:
      return resetVendorUploadResult(state);
    case actionTypes.GET_CONTRACT_HISTORY_SUCCESS:
      return fetchContractHistory(state, action);
    case actionTypes.UPDATE_VENDOR_DETAIL:
      return updateVendorDetail(state, action);
    case actionTypes.GET_VENDOR_PROFILE_IMAGE:
      return fetchVendorProfileLogo(state, action);
    default:
      return state;
  }
};

export default reducer;
